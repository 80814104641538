import PistaLogo from '../../images/pistalogo.jpg';
import './index.css';

const Clients = () => {
    const clientsData = [
        {
            id: 1,
            logo: PistaLogo,
            name: "Pista House",
            location: "Thukkuguda"
        }
    ];

    return (
        <div className="clients-container">
            <h1 className="clients-title">Our Clients</h1>
            <div className="clients-grid">
                {clientsData.map((client) => (
                    <div key={client.id} className="client-card">
                        <div className="client-logo-container">
                            <img 
                                src={client.logo} 
                                alt={`${client.name} logo`}
                                className="client-logo"
                            />
                        </div>
                        <h3 className="client-name">{client.name}</h3>
                        <p className="client-location">{client.location}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Clients;