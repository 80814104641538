import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './components/Navbar/Navbar';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';  
import About from './components/About/About';  
import Gallery from './components/Gallery/Gallery'; 
import Clients from './components/Clients/Clients'; 
import Pricing from './components/Pricing/Pricing';  
import PlaceOrder from './components/PlaceOrder/PlaceOrder';
import Cart from './components/Cart/Cart';
import NotFound from './components/NotFound/NotFound';
import './App.css';

// Create a new LandingPage component that contains all components
const LandingPage = () => {
  return (
    <>
      <Home />
      {/* <About /> */}
      {/* <Gallery /> */}
      {/* <Services /> */}
      <Clients />
      <Pricing />
      <Contact />
    </>
  );
};

// Wrapper component to conditionally render NavBar and Footer
const AppLayout = () => {
  const location = useLocation();
  
  // Routes where NavBar and Footer should not be shown
  const hideNavAndFooterRoutes = ['/menu', '/cart'];
  
  const shouldHideNavAndFooter = hideNavAndFooterRoutes.includes(location.pathname);

  return (
    <div className="App">
      {!shouldHideNavAndFooter && <NavBar />}
      
      <Routes>
        {/* Landing page route that shows all components */}
        <Route exact path="/" element={<LandingPage />} />
        
        {/* Individual routes for separate page access */}
        <Route exact path="/about" element={<About />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/clients" element={<Clients />} />
        {/* <Route exact path="/services" element={<Services />} /> */}
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/contact" element={<Contact />} />
        
        {/* Menu and Cart routes */}
        <Route path="/menu" element={<PlaceOrder />} />
        <Route path="/cart" element={<Cart />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      
      {!shouldHideNavAndFooter && <Footer />}
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppLayout />
    </Router>
  );
}

export default App;