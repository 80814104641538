// PricingComponent.jsx
import React from 'react';
import {Link} from 'react-router-dom'
import './index.css';

const PricingCard = ({ title, price, features, isPopular }) => (
  <div className={`pricing-card ${isPopular ? 'popular' : ''}`}>
    {isPopular && (
      <div className="popular-badge">
        <span>Most Popular</span>
      </div>
    )}
    <div className="pricing-header">
      <h3 className="pricing-title">{title}</h3>
      <div className="pricing-price">
        <span className="price">₹{price}</span>
        <span className="period">/month</span>
      </div>
    </div>
    <ul className="feature-list">
      {features.map((feature, index) => (
        <li key={index} className="feature-item">
          <svg
            className="checkmark"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <Link to="/contact">
    <button className="get-started-btn">
      Get Started
    </button>
    </Link>
  </div>
);

const Pricing = () => {
  const plans = [
    {
      title: 'BASIC',
      price: 149,
      features: [
        'Digital menu',
        'Basic App management system',
        'HungerOps support'
      ]
    },
    {
      title: 'STANDARD',
      price: 1999,
      isPopular: true,
      features: [
        'Digital menu',
        'Complete App management system',
        'Advanced Order Management',
        'Sub Profiles',
        'Orders History',
        'Bill Trackings',
        'HungerOps support'
      ]
    },
    {
      title: 'PREMIUM',
      price: 2999,
      features: [
        'Digital menu',
        'Complete App management system',
        'Advanced Order Management',
        'Sub Profiles',
        'Orders History',
        'Bill Trackings',
        'Accept Payments',
        'HungerOps support'
      ]
    }
  ];

  return (
    <div className="pricing-container">
      <div className="pricing-content">
        <div className="pricing-header-section">
          <h2>We believe in simple & fair pricing</h2>
          <p>
            Try out our platform for an unlimited period of time. Explore our monthly and yearly
            plans and pick the one that best suits your needs.
          </p>
        </div>
        <div className="pricing-cards">
          {plans.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;