// Home.jsx
import React from 'react';
import { Phone } from 'lucide-react';
import { FaWhatsapp } from 'react-icons/fa';
import Video from "../../videos/6011627_Person_People_1280x720 .mp4";
import './index.css';

const Home = () => {
  return (
    <div className="home-container">
      {/* Video Banner Section */}
      <div className="banner-section">
        <video 
          className="background-video"
          autoPlay 
          loop 
          muted 
          playsInline
        >
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        
        {/* Overlay Content */}
        <div className="overlay">
          <div className="content-container">
            <h1 className="main-heading">
              Welcome to HungerOps
            </h1>
            <p className="slogan">
              We simplify order management for hotels
            </p>
          </div>
        </div>
      </div>

      {/* Floating Contact Buttons */}
      <div className="floating-buttons">
        {/* Call Button */}
        <a href="tel:+919391929181" className="contact-button call-button">
          <span className="button-text">Call Us</span>
          <Phone className="button-icon" />
        </a>

        {/* WhatsApp Button */}
        <a 
          href="https://wa.me/9391929181" 
          target="_blank" 
          rel="noopener noreferrer"
          className="contact-button whatsapp-button"
        >
          <span className="button-text">WhatsApp Us</span>
          <FaWhatsapp className="button-icon" />
        </a>
      </div>
    </div>
  );
};

export default Home;