import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const Contact = () => {
  const form = useRef(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    phone_number: '',
    message: ''
  });

  // Validation and handler functions remain the same
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    if (!formData.from_name.trim()) {
      tempErrors.from_name = 'Name is required';
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.from_email) {
      tempErrors.from_email = 'Email is required';
      isValid = false;
    } else if (!emailRegex.test(formData.from_email)) {
      tempErrors.from_email = 'Please enter a valid email';
      isValid = false;
    }

    const phoneRegex = /^\d{10}$/;
    if (!formData.phone_number) {
      tempErrors.phone_number = 'Phone number is required';
      isValid = false;
    } else if (!phoneRegex.test(formData.phone_number)) {
      tempErrors.phone_number = 'Please enter a valid 10-digit phone number';
      isValid = false;
    }

    if (!formData.message.trim()) {
      tempErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs.sendForm('service_msdk7ad', 'template_bplhfd4', form.current, '-By-I176tPKDKLeSb')
        .then((result) => {
          console.log(result.text);
          toast.success('Thank you for your request. We will call you shortly!', {
            position: "top-center",
            autoClose: 3000
          });
          setFormData({
            from_name: '',
            from_email: '',
            phone_number: '',
            message: ''
          });
        }, (error) => {
          console.log(error.text);
          toast.error('Failed to send message. Please try again.', {
            position: "top-center",
            autoClose: 3000
          });
        });
    }
  };

  return (
    <section className="contact-section">
      <div className="container">
        <h2 className="contact-main-heading">Get In Touch</h2>
        <div className="form-container">
          <form className="contact-form" ref={form} onSubmit={sendEmail}>
            <h4>Fill Out The Form</h4>
            <div className="form-fields">
              <div className="input-group">
                <label>Name: </label>
                <input
                  name="from_name"
                  type="text"
                  placeholder="Name"
                  value={formData.from_name}
                  onChange={handleInputChange}
                  className={errors.from_name ? 'error' : ''}
                />
                {errors.from_name && <span className="error-text">{errors.from_name}</span>}
              </div>

              <div className="input-group">
                <label>Email: </label>
                <input
                  name="from_email"
                  type="text"
                  placeholder="Email"
                  value={formData.from_email}
                  onChange={handleInputChange}
                  className={errors.from_email ? 'error' : ''}
                />
                {errors.from_email && <span className="error-text">{errors.from_email}</span>}
              </div>

              <div className="input-group">
                <label>Phone Number:</label>
                <input
                  name="phone_number"
                  type="text"
                  placeholder="Phone Number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  className={errors.phone_number ? 'error' : ''}
                />
                {errors.phone_number && <span className="error-text">{errors.phone_number}</span>}
              </div>
            </div>

            <div className="input-group">
              <label>Message: </label>
              <textarea
                name="message"
                cols="30"
                rows="10"
                value={formData.message}
                onChange={handleInputChange}
                className={errors.message ? 'error' : ''}
              />
              {errors.message && <span className="error-text">{errors.message}</span>}
            </div>

            <button type="submit" className="send-btn">Send</button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Contact;