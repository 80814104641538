import pistamenu from "../../images/ppistamenu.png";
import pistatable from '../../images/realtime restuarent.jpeg';
import pistaqr from "../../images/pistahouse qr.png";
import hungerMenu from "../../images/hunger menu.png";
import './index.css';

const Gallery = () => {
    return (
        <div className="gallery-container">
            <div className="gallery-header">
                <h1>Gallery</h1>
            </div>
            <div className="gallery-grid">
                <div className="gallery-item">
                    <img src={pistamenu} alt="Pista house menu" />
                    <div className="gallery-overlay">
                        <span>Pista House menu @Thukkuguda</span>
                    </div>
                </div>
                <div className="gallery-item">
                    <img src={pistatable} alt="Pista house table" />
                    <div className="gallery-overlay">
                        <span>Pista house table</span>
                    </div>
                </div>
                <div className="gallery-item">
                    <img src={pistaqr} alt="Pista house QR Menu" />
                    <div className="gallery-overlay">
                        <span>Pista House Digital QR for menu</span>
                    </div>
                </div>
                {/* <div className="gallery-item">
                    <img src={hungerMenu} alt="HungerOps menu" />
                    <div className="gallery-overlay">
                        <span>HungerOps menu</span>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Gallery;