import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Minus, X, ShoppingBag, Coffee, Users, CheckCircle } from 'lucide-react';
import { Link , useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './Cart.css';

const Cart = () => {
  const [cartItems, setCartItems] = useState({});
  const [menuItemsMap, setMenuItemsMap] = useState({});
  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  
  // Load cart items and menu items when component mounts
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // Load cart items from localStorage
        const savedCart = localStorage.getItem('cartItems');
        if (savedCart) {
          const parsedCart = JSON.parse(savedCart);
          if (Object.keys(parsedCart).length > 0) {   
            setCartItems(parsedCart);
          }
        }
        // Try to load cached menu items first
        const cachedMenuItems = localStorage.getItem('menuItems');
        if (cachedMenuItems) {
          setMenuItemsMap(JSON.parse(cachedMenuItems));
          setLoading(false);
          setIsCheckoutVisible(true);
          return;
        }
        // If no cached menu items, fetch from API
        const restaurantId = localStorage.getItem('restaurantId');
        if (!restaurantId) {
          throw new Error('Restaurant ID not found');
        }
        const response = await axios.get(`https://ktohbackend.onrender.com/partner-menu`, {
          params: { restaurantId },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        if (response.data && Array.isArray(response.data.partnerMenuItems)) {
          const itemsMap = response.data.partnerMenuItems.reduce((acc, item) => {
            acc[item._id] = item;
            return acc;
          }, {});
          // Cache menu items in localStorage
          localStorage.setItem('menuItems', JSON.stringify(itemsMap));
          setMenuItemsMap(itemsMap);
        } else {
          throw new Error('Invalid menu data received');
        }
      } catch (err) {
        console.error('Error loading data:', err);
        setError(err.message || 'Error loading cart data');
      } finally {
        setLoading(false);
        setIsCheckoutVisible(true);
      }
    };
    loadInitialData();
  }, []);

  const addToCart = (item) => {
    setCartItems(prev => {
      const newCartItems = {
        ...prev,
        [item._id]: (prev[item._id] || 0) + 1
      };
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
      return newCartItems;
    });
  };

  const removeFromCart = (item) => {
    setCartItems(prev => {
      const newCartItems = { ...prev };
      if (newCartItems[item._id] > 0) {
        newCartItems[item._id] -= 1;
        if (newCartItems[item._id] === 0) {
          delete newCartItems[item._id];
        }
        localStorage.setItem('cartItems', JSON.stringify(newCartItems));
      }
      return newCartItems;
    });
  };

  const calculateTotal = () => {
    return Object.entries(cartItems).reduce((total, [itemId, quantity]) => {
      const item = menuItemsMap[itemId];
      return total + (item ? item.price * quantity : 0);
    }, 0);
  };

  // Create cart items array only when cartItems or menuItemsMap changes
  const cartItemsArray = useMemo(() => {
    return Object.entries(cartItems)
      .map(([itemId, quantity]) => {
        const item = menuItemsMap[itemId];
        return item ? { ...item, quantity } : null;
      })
      .filter(Boolean);
  }, [cartItems, menuItemsMap]);

  const handleCheckout = () => {
    setShowOrderModal(true);
  };

  if (loading) {
    return (
      <div className="cart-container">
        <div className="loading">Loading cart items...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="cart-container">
        <div className="error">Error: {error}</div>
        <Link to="/" className="continue-shopping">Return to Menu</Link>
      </div>
    );
  }

  const hasItems = cartItemsArray.length > 0;

  return (
    <div className="cart-container">
      <h2 className="cart-title">Your Cart</h2>
      {!hasItems ? (
        <motion.div
          className="empty-cart"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <ShoppingBag size={64} />
          <p>Your cart is empty</p>
          <Link to="/" className="continue-shopping">Continue Shopping</Link>
        </motion.div>
      ) : (
        <>
          <motion.ul className="cart-items">
            <AnimatePresence>
              {cartItemsArray.map((item) => (
                <motion.li
                  key={item._id}
                  className="cart-item"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  transition={{ duration: 0.3 }}
                >
                  <img src={item.imageUrl} alt={item.name} className="cart-item-image" />
                  <div className="cart-item-details">
                    <h3>{item.name}</h3>
                    <p>₹{item.price.toFixed(2)}</p>
                  </div>
                  <div className="cart-item-quantity">
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      onClick={() => removeFromCart(item)}
                    >
                      <Minus size={18} />
                    </motion.button>
                    <span>{item.quantity}</span>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      onClick={() => addToCart(item)}
                    >
                      <Plus size={18} />
                    </motion.button>
                  </div>
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
          <AnimatePresence>
            {isCheckoutVisible && hasItems && (
              <motion.div
                className="checkout-section"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className="total">
                  <span>Total:</span>
                  <span>₹{calculateTotal().toFixed(2)}</span>
                </div>
                <motion.button
                  className="checkout-button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleCheckout}
                >
                  Proceed to Checkout
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}

  <AnimatePresence>
    {showOrderModal && (
      <motion.div
        className="modal-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="modal-content"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          transition={{ type: "spring", damping: 15 }}
        >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.2
                }}
              >
                <CheckCircle size={64} color="#4CAF50" />
              </motion.div>
              <h2 style={{ color: "#4CAF50", marginTop: "1rem" }}>Thank You for Your Order!</h2>
              <p>Your order is being prepared.</p>
              <div className="modal-icons">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 4, repeat: Infinity, ease: "linear" }}
                >
                  <Coffee size={48} color="#FF6600" />
                </motion.div>
                <Users size={48} color="#3498db" />
              </div>
              <p>Why not have a chat with your family or friends?</p>
              <motion.button
                className="close-modal-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowOrderModal(false)}
              >
                Close
              </motion.button>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
    </div>
  );
};

export default Cart;