import React,{useState, useEffect} from 'react';
import { ShoppingCart, Plus, Minus, ChevronDown, ChevronUp, Search } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSearchParams, Link } from 'react-router-dom';
import axios from 'axios';
import "./PlaceOrder.css";
import defaultImage from "../../images/nophoto.jpg"

const URL_ENCRYPT_SECRET_KEY = 'Hung3r0p$';

const customDecrypt = (encryptedText) => {
  try {
    // Decode the base64 encoded string
    const decodedText = atob(encryptedText);

    let result = '';
    for (let i = 0; i < decodedText.length; i++) {
      // XOR each character with the secret key to decrypt
      result += String.fromCharCode(decodedText.charCodeAt(i) ^ URL_ENCRYPT_SECRET_KEY.charCodeAt(i % URL_ENCRYPT_SECRET_KEY.length));
    }
    return result;
  } catch (error) {
    console.error('Decryption error:', error);
    return '';
  }
};

const safeDecodeAndDecrypt = (encryptedValue) => {
  try {
    const decodedValue = decodeURIComponent(encryptedValue);
    return customDecrypt(decodedValue);
  } catch (error) {
    console.error('Decoding or decryption error:', error);
    return '';
  }
};

const MenuFooter = () => {
  return (
    <div className="menu-footer">
      <div className="menu-footer-title">
        Here Every Dish Tells a Story!
      </div>
      <div className="menu-footer-subtitle">
        Crafted to Delight and Designed for an Effortless Menu Experience. 
        <span>with</span>
        <motion.span
          animate={{ scale: [1, 1.2, 1] }}
          transition={{ 
            repeat: Infinity, 
            duration: 2,
            repeatDelay: 1
          }}
        >
          <span className="heart-icon heart-animate">♥</span>
        </motion.span>
        By HungerOps!
      </div>
    </div>
  );
};

const NoSearchResults = () => {
  return (
    <div className="no-search-results">
      <span>Your search results are not found. Please try other items</span>
      <motion.div
        className="thinking-icon"
        animate={{
          rotate: [0, -20, 20, 0],
          scale: [1, 1.2, 1]
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatDelay: 1
        }}
      >
        🤔
      </motion.div>
    </div>
  );
};

const FilterOptions = ({ activeFilter, onFilterChange, isAllCollapsed, onCollapseToggle }) => {
  return (
    <div className="filter-options">
      <button 
        className={`filter-btn ${activeFilter === 'all' ? 'active' : ''}`}
        onClick={() => onFilterChange('all')}
      >
        <span className="filter-text">All</span>
      </button>
      <button 
        className={`filter-btn ${activeFilter === 'veg' ? 'active' : ''}`}
        onClick={() => onFilterChange('veg')}
      >
        <div className="indicator veg"></div>
        <span className="filter-text">Veg Only</span>
      </button>
      <button 
        className={`filter-btn ${activeFilter === 'non-veg' ? 'active' : ''}`}
        onClick={() => onFilterChange('non-veg')}
      >
        <div className="indicator non-veg"></div>
        <span className="filter-text">Non-Veg</span>
      </button>
      <button 
        className="filter-btn"
        onClick={onCollapseToggle}
      >
        {isAllCollapsed ? (
          <>
            <ChevronDown className="chevron-icon" />
            <span className='exapand-text'>Expand All</span>
          </>
        ) : (
          <>
            <ChevronUp className="chevron-icon" />
            <span className='exapand-text'>Collapse All</span>
          </>
        )}
      </button>
    </div>
  );
};

const CartNotification = ({ itemCount }) => {
  return (
    <AnimatePresence>
      {itemCount > 0 && (
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          exit={{ y: 100 }}
          className="cart-notification"
        >
          <Link to="/cart" className="cart-view-link">
            <div className="cart-notification-content">
              <span className="cart-notification-text">
                {itemCount} {itemCount === 1 ? 'Item' : 'Items'} added
              </span>
              <span className="cart-view-link">
                View Cart
                <svg 
                  className="cart-arrow-icon" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5l7 7-7 7" 
                  />
                </svg>
              </span>
            </div>
          </Link>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const MenuCategory = ({ category, items, addToCart, removeFromCart, cartItems, isAllCollapsed }) => {
  const [isOpen, setIsOpen] = useState(true);

    // Update isOpen when isAllCollapsed changes
    useEffect(() => {
      setIsOpen(!isAllCollapsed);
    }, [isAllCollapsed]);

  return (
    <div className="menu-category">
      <button 
        className="category-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2>{category}</h2>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      
      {isOpen && (
        <div className="category-items">
          {items.length > 0 ? (
            items.map(item => (
              <MenuItem
                key={item._id}
                item={item}
                addToCart={addToCart}
                removeFromCart={removeFromCart}
                cartItems={cartItems}
                disabled={!item.stock}
              />
            ))
          ) : (
            <div className="no-items-message">
              <span>Currently the items are not available</span>
              <motion.div
                className="frowning-icon"
                animate={{
                  rotate: [0, -20, 20, 0],
                  scale: [1, 1.2, 1]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatDelay: 1
                }}
              >
                😕
              </motion.div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const MenuItem = ({ item, addToCart, removeFromCart, cartItems, disabled }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const itemCount = cartItems[item._id] || 0;

  const truncateDescription = (text, maxLength = 50) => {
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength)}...`;
  };

  const styles = {
    fullscreenOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    
    expandedMenuItem: {
      position: 'fixed',
      top: '20%',
      left: '15%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      padding: '1.5rem',
      borderRadius: '1rem',
      width: '60%',
      maxWidth: '400px',
      maxHeight: '90vh',
      overflow: 'auto',
      zIndex: 50,
      boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexDirection: 'column'
    },
    
    expandedContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      height: '100%'
    },
    
    imageContainer: {
      width: '100%',
      aspectRatio: '16/12',
      position: 'relative',
      borderRadius: '0.5rem',
      overflow: 'hidden'
    },
    
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center'
    },
    
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      marginTop: '0.5rem'
    },
    
    title: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      margin: 0
    },
    
    description: {
      marginTop: '0.5rem',
      color: '#666666',
      fontSize: '0.875rem'
    },
    
    price: {
      marginTop: '0.5rem',
      fontSize: '1.25rem',
      fontWeight: '600',
      color: '#000'
    },
    
    closeButton: {
      marginTop: '1rem',
      padding: '0.75rem',
      backgroundColor: '#f3f4f6',
      color: '#4b5563',
      border: 'none',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      fontWeight: '500'
    },
    
    vegIndicator: {
      width: '1rem',
      height: '1rem',
      border: '1px solid',
      borderRadius: '0.25rem',
      position: 'relative',
      flexShrink: 0
    },
    
    vegIndicatorInner: {
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  return (
    <>
    <motion.div
      className={`menu-item ${disabled ? 'disabled' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0}}
      transition={{ duration: 0.3 }}
      whileHover={!disabled && { scale: 1.02 }}
      whileTap={!disabled && { scale: 0.98 }}
      onClick={() => setIsExpanded(!isExpanded)}
      layout
    >
       <div className="item-image-container">
          <img 
            src={item.imageUrl || defaultImage} 
            alt={item.name} 
            className="item-image" 
          />
        </div>
        <div className="item-content">
          <div className="item-details">
            <div className="item-name-container">
              <div className={`veg-indicator ${item.isVeg ? 'veg' : 'non-veg'}`}>
                <div className="veg-indicator-inner"></div>
              </div>
              <h3>{item.name}</h3>
            </div>
            <p className="description">
              {truncateDescription(item.description)}
            </p>
            <p className="price">₹{item.price.toFixed(2)}</p>
          {disabled && (
            <div className="out-of-stock-badge">
              Out of Stock
            </div>
          )}
          {/* <p className="availability">Stock: {item.stock ? 'Available' : 'Out of Stock'}</p> */}
        </div>
        <div className="item-actions">
        {!disabled && (
          <AnimatePresence mode="wait">
            {/* {itemCount === 0 ? (
              <motion.button
              key="add"
              className="add-to-cart"
              onClick={() => addToCart(item)}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2 }}
              >
                Add to Cart
              </motion.button>
            ) : (
              <motion.div
                key="quantity"
                className="quantity-control"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
              >
                 <motion.button 
                  className="quantity-btn"
                  onClick={() => removeFromCart(item)} 
                  whileTap={{ scale: 0.95 }}
                >
                  <Minus size={20} />
                </motion.button>
                <span className="quantity-display">{itemCount}</span>
                <motion.button 
                  className="quantity-btn"
                  onClick={() => addToCart(item)} 
                  whileTap={{ scale: 0.95 }}
                >
                  <Plus size={20} />
                </motion.button>
              </motion.div>
            )} */}
          </AnimatePresence>
        )}
        </div>
      </div>
    </motion.div>
    <AnimatePresence>
        {isExpanded && (
          <>
            <motion.div
              style={styles.fullscreenOverlay}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsExpanded(false)}
            />
            <motion.div
              style={styles.expandedMenuItem}
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ type: 'spring', damping: 25, stiffness: 400 }}
            >
              <div style={styles.expandedContent}>
                <div style={styles.imageContainer}>
                  <img 
                    src={item.imageUrl || defaultImage} 
                    alt={item.name}
                    style={styles.image}
                  />
                </div>
                <div>
                  <div style={styles.headerContainer}>
                    <div 
                      style={{
                        ...styles.vegIndicator,
                        borderColor: item.isVeg ? '#00a962' : '#ff0000'
                      }}
                    >
                      <div 
                        style={{
                          ...styles.vegIndicatorInner,
                          backgroundColor: item.isVeg ? '#00a962' : '#ff0000'
                        }}
                      />
                    </div>
                    <h2 style={styles.title}>{item.name}</h2>
                  </div>
                  <p style={styles.description}>{item.description}</p>
                  <p style={styles.price}>₹{item.price.toFixed(2)}</p>
                </div>
                <button
                  style={styles.closeButton}
                  onClick={() => setIsExpanded(false)}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#e5e7eb'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#f3f4f6'}
                >
                  Close
                </button>
              </div>
            </motion.div>
          </>
        )}
    </AnimatePresence>
    </>
  );
};

const PlaceOrder = () => {
  const [searchParams] = useSearchParams();
  const encryptedRestaurantId = searchParams.get('restaurantId');
  const encryptedTable = searchParams.get('table');

  const restaurantId = safeDecodeAndDecrypt(encryptedRestaurantId);
  const table = safeDecodeAndDecrypt(encryptedTable);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);   
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem('cartItems');
    return savedCart ? JSON.parse(savedCart) : {};
  });
  const [categorizedItems, setCategorizedItems] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [restaurantDetails, setRestaurantDetails] = useState({
    name: '',
    image: ''
  });
  const [isAllCollapsed, setIsAllCollapsed] = useState(false);

  // const restaurantId = searchParams.get('restaurantId');
  const totalItems = Object.values(cartItems).reduce((sum, count) => sum + count, 0);

    // Add handler for collapse/expand toggle
    const handleCollapseToggle = () => {
      setIsAllCollapsed(!isAllCollapsed);
    }

  // Cart management functions
  const addToCart = (item) => {
    setCartItems(prevItems => {
      const newItems = {
        ...prevItems,
        [item._id]: (prevItems[item._id] || 0) + 1
      };
      localStorage.setItem('cartItems', JSON.stringify(newItems));
      return newItems;
    });
  };

  const removeFromCart = (item) => {
    setCartItems(prevItems => {
      const newItems = { ...prevItems };
      if (newItems[item._id] > 0) {
        newItems[item._id] -= 1;
        if (newItems[item._id] === 0) {
          delete newItems[item._id];
        }
      }
      localStorage.setItem('cartItems', JSON.stringify(newItems));
      return newItems;
    });
  };

  useEffect(() => {
    const fetchMenuItems = async () => {
      if (!restaurantId) {
        setError('Restaurant ID is missing');
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        setError(null);

        localStorage.setItem('restaurantId', restaurantId);

        const response = await axios.get(`https://ktohbackend.onrender.com/partner-menu`, {
          params: { restaurantId },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        // console.log("res", response);

        const { data } = response;
        
        // Check if data exists and has partnerMenuItems property
        if (!data || !data.partnerMenuItems || !Array.isArray(data.partnerMenuItems)) {
          throw new Error('Invalid data format received from API');
        }

        // Extract and set restaurant details
        if (data.restaurant) {
          setRestaurantDetails({
            name: data.restaurant.restaurantName || 'Restaurant',
            image: data.restaurant.restaurantImage || defaultImage
          });
        }

        // Use partnerMenuItems array from the response
        const menuItemsArray = data.partnerMenuItems;

        // Organize items by category
        const itemsByCategory = menuItemsArray.reduce((acc, item) => {
          const category = item.itemCategory || 'Other';
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(item);
          return acc;
        }, {});

        setCategorizedItems(itemsByCategory);
        setMenuItems(menuItemsArray);
      } catch (error) {
        console.error('Error fetching menu:', error);
        setError(error.message || 'Failed to fetch menu items');
      } finally {
        setLoading(false);
      }
    };

    fetchMenuItems();
  }, [restaurantId]);
  
  useEffect(() => {
    const filteredItems = menuItems.filter((item) => {
      const matchesSearch = item.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesFilter = 
        activeFilter === 'all' ? true :
        activeFilter === 'veg' ? item.isVeg :
        activeFilter === 'non-veg' ? !item.isVeg :
        true;
      return matchesSearch && matchesFilter;
    });

  const itemsByCategory = filteredItems.reduce((acc, item) => {
    const category = item.itemCategory || 'Other';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  setCategorizedItems(itemsByCategory);
}, [menuItems, searchQuery, activeFilter]);
  
  if (!restaurantId) {
    return <div className="error">Error: Restaurant ID is required</div>;
  }

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading">Loading menu items...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <div className="error">Error: {error}</div>
        <div className="error-details">
          <button 
            className="retry-button"
            onClick={() => {
              setLoading(true);
              setError(null);
              window.location.reload();
            }}
          >
            Retry Loading Menu
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="place-order">
      <header>
        <img src={restaurantDetails.image || "appIcon.png"} alt="HungerOps Logo" className="logo" />
        <h1>{restaurantDetails.name + '  Menu ' || "Restuarent Menu"}</h1>
        {/* <Link to="/cart" className="cart-icon">
          <ShoppingCart size={24} />
          <AnimatePresence>
            {totalItems > 0 && (
              <motion.span
                className="cart-count"
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              >
                {totalItems}
              </motion.span>
            )}
          </AnimatePresence>
        </Link> */}
      </header>
      <div className="search-container">
        <input type="text" className="search-input" placeholder="Search for 'Biryani'" value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} />
        <div className="search-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
      </div>
      <FilterOptions 
        activeFilter={activeFilter} 
        onFilterChange={setActiveFilter}
        isAllCollapsed={isAllCollapsed}
        onCollapseToggle={handleCollapseToggle}
      />
      <div className="menu-categories">
        {Object.entries(categorizedItems).map(([category, items]) => (
          <MenuCategory
            key={category}
            category={category}
            items={items}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            cartItems={cartItems}
            isAllCollapsed={isAllCollapsed}
          />
        ))}
        {Object.values(categorizedItems).every(items => items.length === 0) && (
          <NoSearchResults />
        )}
      </div>
      <MenuFooter />
      <CartNotification itemCount={totalItems} />
    </div>
  );
};

export default PlaceOrder;