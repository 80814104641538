// AboutUs.jsx
import React from 'react';
import Imag1 from '../../images/restaurant-interior.jpg';
import Image2 from '../../images/restuarent2.jpg';
import './index.css';

const AboutUs = () => {
  return (
    <div className="about-container">
      {/* Header Section */}
      <div className="header-section">
        <div className="header-overlay">
          <h1>About Us</h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="text-content">
          <p>
            We introduce ourselves as one of the leading Hotel management software distibutors 
            in the eastern region of India. Our objective is to 
            camouflage an environment which gives a special billing software to the 
            Hotels and budget great care has been taken in working out cost 
            effective sofware that suits the client budget.
          </p>
          {/* <p>
            We help our client in realizing their dreams by creating esthetically 
            pleasing as well as cost effective interior design. Our term works with 
            dedication and care so as to evolve scintillating design concepts in 
            interior and exterior application in the office premises, hospital, 
            school, hotel, restaurants, auditorium, showrooms and residence and 
            implementing various projects on turnkey basis.
          </p> */}
          <p>
            We are confidence to achieve the leadership in this trade with in few years
          </p>
        </div>
        
        <div className="image-grid">
          <div className="image-box">
            <img src={Imag1} alt="Modern interior design" />
          </div>
          <div className="image-box">
            <img src={Image2} alt="Kitchen design" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;