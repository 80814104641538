// Footer.jsx
import React from 'react';
import { Phone, Mail, MapPin, Instagram } from 'lucide-react';
import { FaWhatsapp } from 'react-icons/fa';
import './index.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-grid">
          {/* About Us Section */}
          <div className="footer-section">
            <h2 className="section-title">About us</h2>
            <p className="about-text">
              HungerOps an Restaurant software provide company working with hotels.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="footer-section">
            <h2 className="section-title">Quick Links</h2>
            <ul className="quick-links">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About us</a></li>
              <li><a href="/gallery">Gallery</a></li>
              <li><a href="/clients">Clients</a></li>
              {/* <li><a href="/services">Services</a></li> */}
              <li><a href="/pricing">Pricing</a></li>
              <li><a href="/contact">Contact us</a></li>
            </ul>
          </div>

          {/* Corporate Office Section */}
          <div className="footer-section">
            <h2 className="section-title">Corporate Office</h2>
            <div className="contact-info">
              <a href="tel:+919391929181" className="contact-item">
                <Phone className="contact-icon" />
                <span>+91 9391929181</span>
              </a>
              
              <a href="mailto:hugerops.in@gmail.com" className="contact-item">
                <Mail className="contact-icon" />
                <span>hugerops.in@gmail.com</span>
              </a>
              
              <div className="contact-item">
                <MapPin className="contact-icon" />
                <span>
                  Vasavi Nagar, Secundrabad, Hyderabad, Pin-500020
                </span>
              </div>

              <div className="social-links">
                <a href="https://www.instagram.com/hungerops?igsh=MXNuOTNtY2FqdDhoOQ==" className="social-icon">
                  <Instagram />
                </a>
                <a href="mailto:hugerops.in@gmail.com" className="social-icon">
                  <Mail />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="copyright">
          <p>© Copyright 2024 All rights reserved by HungerOps</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;